/* src/components/ManageProposals.css */

/* Estilos generales para la página ManageProposals */
.manage-proposals-page {
    text-align: center;
    background: linear-gradient(to right, #1e3c72, #2a5298);
    min-height: 100vh;
    padding: 20px;
    color: white;
  }
  
  .manage-proposals-page h1 {
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #fff;
  }
  
  .manage-proposals-page h2,
  .manage-proposals-page h3 {
    color: #fff;
    margin-bottom: 20px;
  }

  .proposals-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
  }
  
  .proposal-item {
    background: #fff;
    color: #333;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .proposal-item p {
    margin: 5px 0;
    font-size: 1rem;
  }
  
  .proposal-item strong {
    font-weight: bold;
  }
  
  .proposal-item button {
    margin-top: 15px;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .proposal-item button:hover {
    background: #0056b3;
  }
  
  .response-form {
    background: #f8f9fa;
    color: #333;
    border-radius: 12px;
    padding: 20px;
    margin-top: 30px;
    text-align: left;
  }
  
  .response-form h2,
  .response-form h3 {
    color: #333;
  }
  
  .response-form label {
    display: block;
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .response-form select,
  .response-form input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  .response-form button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s ease;
  }
  
  .response-form button:first-of-type {
    background: #28a745;
    color: #fff;
  }
  
  .response-form button:first-of-type:hover {
    background: #218838;
  }
  
  .response-form button:last-of-type {
    background: #f44336;
    color: #fff;
  }
  
  .response-form button:last-of-type:hover {
    background: #e41e26;
  }
  
  .no-proposals-message,
  .access-message {
    font-size: 1.2rem;
    color: #fff;
    margin-top: 50px;
  }
  
  @media (max-width: 768px) {
    .proposal-item,
    .response-form {
      padding: 15px;
    }
  
    .proposal-item button,
    .response-form button {
      width: 100%;
      margin-top: 10px;
    }
  }
  