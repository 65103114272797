/* PurchaseCompleted.css */
.PurchaseCompleted {
  text-align: center;
  background: linear-gradient(to right, #1e3c72, #2a5298);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
}

.purchase-completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(42, 82, 152, 0.9); /* Transparencia para mejor legibilidad */
  padding: 30px;
  margin: 50px auto;
  max-width: 600px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.purchase-completed-container h2 {
  color: #ffffff; /* Blanco para mejor contraste */
  margin-bottom: 20px;
  font-size: 28px;
}

.purchase-completed-container p {
  font-size: 18px;
  color: #ffffff; /* Blanco para mejor contraste */
  margin: 10px 0;
}

/* Estilos para el botón "Volver al inicio" */
.go-back-button {
  margin-top: 20px;
  background-color: #007bff; /* Azul brillante para el botón */
  color: white;
  padding: 12px 30px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.go-back-button:hover {
  background-color: #0056b3; /* Azul más oscuro cuando se pasa el mouse */
  transform: scale(1.05); /* Efecto de aumento al pasar el mouse */
}
