.App {
  text-align: center;
  background: linear-gradient(0, #0d2247, #1e3c72);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.App-logo {
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border: 3px solid white;
}

.welcome-container {
  text-align: center;
  padding: 40px;
}

.welcome-container h1 .highlight {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #ffcf4a;
  background: linear-gradient(to right, #ffcf4a, #ff9f00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tag {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.8);
}

.welcome-buttons {
  display: flex;
  justify-content: center;
}

.welcome-button {
  padding: 12px 25px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #3213bd, #2575fc);
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.3s ease;
}

.welcome-button.secondary {
  background: linear-gradient(135deg, #2575fc, #3213bd);
}

.welcome-button:hover {
  transform: translateY(-5px);
  transform: scale(1.05);
  background: linear-gradient(to left, #ffcf4a, #ff9f00);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

.welcome-button:active {
  transform: translateY(1px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.text-colored {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}

.modal,
.modal-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content,
.modal-content-2 {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  width: 40vw;
  max-height: 60vh;
  overflow-y: auto;
}

.modal-content-2 {
  width: 100%;
  max-width: 500px;
}

.modal-title {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.modal-label {
  display: flex;
  flex-direction: column;
  color: #555;
  font-size: 1rem;
}

.modal-input,
.modal-select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  width: 90%;
}

.modal-checkbox {
  margin-top: 10px;
  transform: scale(1.2);
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.3s ease;
}

.confirm-button {
  background: #4caf50;
  color: white;
}

.confirm-button:hover {
  background: #45a049;
}

.cancel-button {
  background: #f44336;
  color: white;
}

.cancel-button:hover {
  background: #e41e26;
}

.pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination button {
  margin: 5px;
  padding: 8px 12px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.pagination button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.pagination .active {
  background-color: #0056b3;
  font-weight: bold;
}

.bonus-item {
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.fixtures-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.fixture-item {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.league-info p {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.teams-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.team-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.team-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  background: #f0f0f0;
  padding: 5px;
}

.team-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.vs-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
}

.auction-details {
  color: #555;
  font-size: 0.9rem;
  line-height: 1.4;
}

.fixture-date p{
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

.fixture-odds {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.odds-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.odds-info p {
  margin: 0;
  font-weight: bold;
}

.odds-values {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.odds-value {
  text-align: center;
}

.odds-value p {
  margin: 0;
}

.bonos-info {
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
}

.buy-button,
.recommendation-button {
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin: 10px 5px;
  transition: transform 0.3s ease, background 0.3s ease;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.buy-button {
  color: #fff;
  background: #2c2b30;
}

.recommendation-button {
  color: #333;
  background: #ffffff;
}

.buy-button:hover{
  background: #218838;
  transform: translateY(-5px);
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}
.recommendation-button:hover {
  transform: translateY(-5px);
  transform: scale(1.05);
  background: linear-gradient(to left, #ffcf4a, #ff9f00);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

.buttons-section {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  width: 100%;
}

.no-fixtures {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #888;
}

.user-info-container {
  position: fixed;
  top: 0;
  left: 0; /* Cambiado de -300px para facilitar el uso de transform */
  height: 100%;
  width: 300px;
  background: #f9f9f9; /* Color de fondo más suave */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0 15px 15px 0;
  padding: 20px 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto; /* Para habilitar el desplazamiento */
  transition: transform 0.3s ease-in-out;
  max-height: 100vh; /* No excede la pantalla */
  direction: rtl; /* Cambiar la dirección para que el scrollbar aparezca a la izquierda */
}

.external-toggle-button {
  position: fixed;
  top: 5%;
  left: 10px; /* Justo al lado del contenedor cerrado */
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: none;
  background: #898989;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  transition: left 0.3s ease-in-out;
}

.external-toggle-button.open {
  left: 310px; /* Ajustar la posición cuando el contenedor está abierto */
}

.external-toggle-button:hover {
  background: #3e3e3e;
}

.user-info-container.open {
  transform: translateX(0); /* Abierto completamente */
}

.user-info-container.closed {
  transform: translateX(-94%); /* Oculto fuera de la pantalla */
}

.user-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-info h3,
.user-info p {
  color: #333;
}

.user-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.user-details {
  overflow-y: auto; /* Permite el desplazamiento si el contenido es largo */
  flex-grow: 1; /* Ocupa el espacio disponible */
}

.user-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px auto;
}

.logout-button,
.add-money-button,
.my-requests-button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background: #007bff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

.logout-button:hover,
.add-money-button:hover,
.my-requests-button:hover {
  background: #0056b3;
}

.wallet-balance,
.bonuses-section,
.worker-status {
  margin: 10px 0;
  color: #666;
}

.worker-status p {
  font-size: 0.9rem;
  color: #666;
}

.admin-discount-section {
  background: #e9ecef; /* Fondo distintivo */
  padding: 15px;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.admin-discount-section h3 {
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.discount-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: #333;
}

.discount-input {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.activate-discount-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background: #28a745;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

.activate-discount-button:hover {
  background: #218838;
}

.worker-status p {
  font-size: 0.9rem;
  color: #666;
  margin-top: 10px;
}

.filters-container {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  background: linear-gradient(0, #0d2247, #1e3c72);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.filter-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-label {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.filter-input,
.filter-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.filter-input:focus,
.filter-dropdown:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

.recommendation-result {
  margin-top: 10px;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  font-family: monospace;
  font-size: 0.9rem;
}

.price-info p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.price-info h4 {
  font-size: 1rem;
  font-weight: bold;
  color: #910101;
}

.fixture-bet-result {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f8ff;
  border-radius: 5px;
}

.fixture-bet-result p {
  margin: 0;
  font-weight: bold;
  color: #333;
}

.result-info p{
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.view-offers-page {
  padding: 20px;
}

.page-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.auction-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.auction-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.error-item {
  background-color: #ffe5e5;
  color: #d9534f;
}

.proposal-button {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.3s;
}

.proposal-button:hover {
  background: linear-gradient(135deg, #ffcf4a, #ff9f00);
}

.no-data-message,
.no-permission-message,
.login-required-message {
  font-size: 1.2rem;
  text-align: center;
  color: #888;
}

.back-button {
  margin-top: 20px;
  background: #6c757d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.back-button:hover {
  background: #5a6268;
}

.offer-details {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #555;
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-label {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.form-input {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  width: 50%;
}

.form-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.offer-buttons {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
}